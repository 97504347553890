import React from "react";

import Input from "../../ui-components/Input";
import Button from "../../ui-components/Button";
import Tabs from "../../ui-components/Tabs";
import NotCoveredAndForward from "./NotCoveredAndForward";
import KiffAndCollar from "./KiffAndCollar";
import Modal from "../../ui-components/Modal";

function HedgingDetailsDialog({open, toggleOpen, data, tabIndex, setTabIndex}) {

  return (
      <div>
        <Modal opened={open} onExit={toggleOpen}>
          <div className="m-4">
            <h3 className="text-center text-xl font-extrabold text-gray-900">
              Dettaglio Coperture{data.covered_how !== "" ? " - " + data.covered_how : ""}
            </h3>
          </div>
          {data.covered_how !== '' && data.covered_how !== 'Forward' &&
              <div>
                <div className="flex flex-col items-center mb-4">
                  <div className="mb-4">
                    <Tabs
                        setActiveTab={setTabIndex}
                        active={tabIndex}
                        tabs={[{name: "Scenario 1"},
                          {name: "Scenario 2"},
                          {name: "Scenario 3"}]}
                    />
                  </div>
                  {tabIndex === 0 ?
                      <p className="text-sm font-bold">Cambio sotto al Floor</p> :
                      (tabIndex === 1 ?
                          <p className="text-sm font-bold">Cambio compreso tra Cap e Floor</p> :
                          <p className="text-sm font-bold">Cambio sopra al Cap</p>)}
                </div>
              </div>}
          <div className="grid grid-cols-3 gap-x-5">
            <div>
              <Input
                  id="cod_hedging"
                  label="Cod. Copertura"
                  name="cod_hedging"
                  type="text"
                  value={data.cod_hedging}
              />
            </div>
            <div>
              <Input
                  id="cod_order"
                  label="Cod. Ordine"
                  name="cod_order"
                  type="text"
                  value={data.cod_order}
              />
            </div>
            <div>
              <Input
                  id="cod_job"
                  label="Cod. Commessa"
                  name="cod_commessa"
                  type="text"
                  value={data.cod_job}
              />
            </div>
          </div>
          {(data.covered_how === '' || data.covered_how === 'Forward') &&
              <NotCoveredAndForward data={data}/>}
          {data.covered_how !== '' && data.covered_how !== 'Forward' &&
              <KiffAndCollar data={data} tabIndex={tabIndex} covered_how={data.covered_how}/>
          }
          <div className="grid grid-cols-2 gap-x-5">
            <div>
              <Input
                  id="dat_expiration"
                  label="Data Scadenza"
                  name="dat_expiration"
                  type="text"
                  value={data.dat_expiration ? new Date(data.dat_expiration).toLocaleDateString() : ''}
              />
            </div>
            <div>
              <Input
                  id="dat_expiration_hedging"
                  label="Data Scadenza Copertura"
                  name="dat_expiration_hedging"
                  type="text"
                  value={data.dat_expiration_hedging ? new Date(data.dat_expiration_hedging).toLocaleDateString() : ''}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <Button onClick={toggleOpen} color="primary">
              Chiudi
            </Button>
          </div>
        </Modal>
      </div>
  );
}

export default HedgingDetailsDialog;