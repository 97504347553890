import React from "react";
import {withRouter} from "react-router-dom";
import apiRequest from "../services/apiRequest";
import ErrorPage from "./pages/ErrorPage";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false, error: null, info: null};
  }

  componentDidCatch = (error, info) => {
    this.setState({hasError: true, error, info});

    const errorMessage = error?.message || ""
    const errorStack = (error?.stack || "")
      .replace(errorMessage, "")
      .split("\n    ")
    // send api to server

    const asyncErrorPost = async () => apiRequest.post(
      "/audit/error_frontend",
      {
        stack: errorStack,
        error: errorMessage,
        from_: this.props.from || "main",
        path: `${window.location.pathname}${window.location.search}`,
        agent:
          window.navigator && window.navigator.userAgent
            ? window.navigator.userAgent
            : "",
      },
      {publicRequest: true, audit: true}
    );

    try {
      asyncErrorPost();
    } catch (error) {
      // error client error
    }
  };

  render() {
    const {children, component, hide} = this.props;

    const {hasError} = this.state;

    if (hasError) {
      if (component) {
        return component;
      }
      if (hide) {
        return null;
      }

      return <ErrorPage/>;
    }

    return children;
  }
}

export default withRouter(ErrorBoundary);
