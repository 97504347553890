import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {
  getTooltip,
  getXYChart,
  getRenderAxisX,
  getRenderAxisY,
  addLicense
} from "./commons";
import Input from "../../ui-components/Input";
import Select from "../../ui-components/Select";

const rangeOptionsShort = [
  {value: 1, name: "1 Settimana"},
  {value: 2, name: "2 Settimane"},
  {value: 4, name: "4 Settimane"},
];

const rangeOptionsLong = [
  {value: 8, name: "2 Mesi"},
  {value: 12, name: "3 Mesi"},
  {value: 28, name: "6 Mesi"},
  {value: 52, name: "12 Mesi"},
  {value: 80, name: "18 Mesi"}
];


function ModelTrendLine({id, data, shortTerm}) {
  const [chartRef, setChartRef] = useState(null);
  const [activeHorizonShort, setActiveHorizonShort] = useState(1);
  const [activeHorizonLong, setActiveHorizonLong] = useState(8);
  const [incrementProbabilityHorizonShort, setIncrementProbabilityHorizonShort] =
      useState(0);
  const [incrementProbabilityHorizonLong, setIncrementProbabilityHorizonLong] =
      useState(0);

  const getDataShort = useCallback(() => {
    const orderedResults = _.sortBy(data, "dat_previsione");
    const horizonKeyShort = data.find((a) => a.horizon === activeHorizonShort);
    const history = orderedResults.filter(({flag_previsione}) => !flag_previsione);
    const previsionShort = orderedResults.filter(({flag_previsione, horizon}) =>
        flag_previsione && (activeHorizonShort === horizon || !horizon));
    setIncrementProbabilityHorizonShort(horizonKeyShort.proba_incr);
    return [
      ...history.map((v) => ({
        ...v,
        val_storico: v.val_previsione,
        val_previsione_bottom: null,
        val_previsione_top: null,
        val_previsione: null,
      })),
      ...previsionShort.map((v, index) => ({
        ...v,
        val_storico: null,
        val_previsione_bottom: !v.horizon ? v.val_previsione : v.val_lower,
        val_previsione_top: !v.horizon ? v.val_previsione : v.val_upper,
      })),
    ];
  }, [data, activeHorizonShort]);

  const getDataLong = useCallback(() => {
    const orderedResults = _.sortBy(data, "dat_previsione");

    const horizonKeyLong = data.find((a) => a.horizon === activeHorizonLong);

    const history = orderedResults.filter(
        ({flag_previsione}) => !flag_previsione
    );

    const previsionLong = orderedResults.filter(
        ({flag_previsione, horizon}) =>
            flag_previsione && (activeHorizonLong === horizon || !horizon)
    );

    setIncrementProbabilityHorizonLong(horizonKeyLong.proba_incr);

    return [
      ...history.map((v) => ({
        ...v,
        val_storico: v.val_previsione,
        val_previsione_bottom: null,
        val_previsione_top: null,
        val_previsione: null,
      })),
      ...previsionLong.map((v, index) => ({
        ...v,
        val_storico: null,
        val_previsione_bottom: !v.horizon ? v.val_previsione : v.val_lower,
        val_previsione_top: !v.horizon ? v.val_previsione : v.val_upper,
      })),
    ];
  }, [data, activeHorizonLong]);


  useLayoutEffect(() => {

    addLicense();
    let root = am5.Root.new(id);
    root.setThemes([am5themes_Animated.new(root)])
    root.dateFormatter.set("dateFormat", "yyyy-MM-dd")


    // Create chart
    let chart = getXYChart(root, {layout: root.verticalLayout})

    chart.paddingRight = 0;
    chart.data = shortTerm ? getDataShort() : getDataLong();
    chart.horizon = shortTerm ? activeHorizonShort : activeHorizonLong;

    // Create axes
    let xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
      baseInterval: {timeUnit: "day", count: 1},
      renderer: getRenderAxisX(root, false)
    }))

    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      renderer: getRenderAxisY(root)
    }))

    // Cursor zoom for xaxis
    chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomX",
      xAxis: xAxis
    }));

    // Scrollbar for axis
    chart.set("scrollbarX", am5.Scrollbar.new(root, {orientation: "horizontal"}));
    chart.set("scrollbarY", am5.Scrollbar.new(root, {orientation: "vertical"}));

    // Shared settings for series
    const sharedSettings = (name, yvalue) => ({
      minBulletDistance: 15,
      strokeWidth: 2,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: "dat_previsione",
      valueYField: yvalue,
      name: name,
    })

    // Storico
    let series_history = chart.series.push(am5xy.LineSeries.new(root, {
      ...sharedSettings("Storico", "val_storico"),
      tooltip: getTooltip(root, "{dat_previsione.formatDate()}: [bold]ExRate[/] {val_storico}"),
    }))

    // Previsioni
    let series_prevision = chart.series.push(am5xy.LineSeries.new(root, {
      ...sharedSettings("Previsione", "val_previsione"),
      tooltip: getTooltip(root, "{dat_previsione.formatDate()}: [bold]ExRate[/] {val_previsione}"),
    }))
    series_prevision.strokes.template.setAll({
      strokeDasharray: [8, 4]
    });

    // Previsione top
    let series_top = chart.series.push(am5xy.LineSeries.new(root, {
      ...sharedSettings("Previsione Max", "val_previsione_top"),
      tooltip: getTooltip(root, "{dat_previsione.formatDate()}: [bold]ExRate[/] {val_previsione_top}"),
    }))
    series_top.strokes.template.setAll({
      strokeDasharray: [8, 4]
    });

    // Previsione bottom
    let series_bottom = chart.series.push(am5xy.LineSeries.new(root, {
      ...sharedSettings("Previsione Min", "val_previsione_bottom"),
      tooltip: getTooltip(root, "{dat_previsione.formatDate()}: [bold]ExRate[/] {val_previsione_bottom}"),
      openValueYField: "val_previsione_top",
    }))
    series_bottom.strokes.template.setAll({
      strokeDasharray: [8, 4]
    });
    series_bottom.fills.template.setAll({
      fillOpacity: 0.3,
      visible: true
    });


    let series = [series_history, series_prevision, series_top, series_bottom]
    // Add data
    series.forEach(s => {
      s.data.processor = am5.DataProcessor.new(root, {
        dateFields: ['dat_previsione'],
        dateFormat: "yyyy-MM-dd"
      })
      s.data.setAll(chart.data)
    })

    series_prevision.events.once("datavalidated", function () {
      const latestDate = new Date(
          chart.data[chart.data.length - 1].dat_previsione
      );
      const threeMonthsBefore = new Date(latestDate);
      threeMonthsBefore.setDate(shortTerm ? threeMonthsBefore.getDate() - (7 * activeHorizonShort + 90) :
          threeMonthsBefore.getDate() - (7 * activeHorizonLong + 90));
      latestDate.setDate(latestDate.getDate() + 1);
      xAxis.zoomToDates(threeMonthsBefore, latestDate);
    });

    let legend = chart.children.push(am5.Legend.new(root, {
      x: am5.percent(50),
      centerX: am5.percent(50),
      clickTarget: "none"
    }));
    legend.data.setAll(chart.series.values);

    // Make stuff animate on load
    chart.appear(2500);

    setChartRef(chart);

    return () => {
      root.dispose();
    };
  }, [activeHorizonShort, activeHorizonLong, getDataShort, getDataLong, id, shortTerm]);

  useEffect(() => {
        if (chartRef && chartRef.horizon !== activeHorizonShort) {
          chartRef.data = getDataShort();
          chartRef.horizon = activeHorizonShort;
        }
      }, // eslint-disable-next-line
      [activeHorizonShort]);

  useEffect(() => {
        if (chartRef && chartRef.horizon !== activeHorizonLong) {
          chartRef.data = getDataLong();
          chartRef.horizon = activeHorizonLong;
        }
      }, // eslint-disable-next-line
      [activeHorizonLong]);

  return <>
    <div className="flex flex-row items-center justify-around">
      <div>
        <Input
            id="val_exposure_tot"
            label="Prob. di incremento"
            type="text"
            value={shortTerm ? `${(incrementProbabilityHorizonShort * 100).toFixed(2)}%` :
                `${(incrementProbabilityHorizonLong * 100).toFixed(2)}%`}
            readOnly
        />
      </div>
      {shortTerm && <h1 className="text-sm font-bold">A breve termine</h1>}
      {shortTerm === false && <h1 className="text-sm font-bold">A lungo termine</h1>}
      <div>
        <Select
            name="horizon"
            value={shortTerm ? activeHorizonShort : activeHorizonLong}
            options={shortTerm ? rangeOptionsShort : rangeOptionsLong}
            onChange={(e) => {
              shortTerm ?
                  setActiveHorizonShort(parseInt(e.target.value)) : setActiveHorizonLong(parseInt(e.target.value));
            }}
            label="Range previsione"
        />
      </div>
    </div>
    <div id={id} style={{minWidth: "500px", minHeight: "350px"}}/>
  </>
}

export default React.memo(ModelTrendLine, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
