import React from "react";

import Input from "../../ui-components/Input";
import {chooseExrCollar, chooseExrKiff} from "../../utils/chooseExr";
import {toCurrency} from "../../utils";

function KiffAndCollar({data, tabIndex, covered_how}) {

  return (
      <div>
        <div className="grid grid-cols-4 gap-x-5">
          <div>
            <Input
                id="val_cambio_floor"
                label="Floor"
                name="val_exr_hedging_call"
                type="text"
                value={data.val_exr_hedging_call}
            />
          </div>
          <div>
            <Input
                id="val_cambio_actual"
                label="Actual"
                name="val_exr_close"
                type="text"
                value={data.val_exr_close}
            />
          </div>
          <div>
            <Input
                id="val_cambio_contrattuale"
                label="Contrattuale"
                name="val_exr_fixed"
                type="text"
                value={data.val_exr_fixed}
            />
          </div>
          <div>
            <Input
                id="val_cambio_cap"
                label="Cap"
                name="val_exr_hedging"
                type="text"
                value={data.val_exr_hedging}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-5">
          <div>
            <Input
                id="val_hedging"
                label="Importo Dollari ($)"
                name="val_hedging"
                type="text"
                value={toCurrency(data.val_hedging, 2)}
            />
          </div>
          <div>
            <Input
                id="val_foreseen_payment"
                label="Importo Incasso ($)"
                name="val_foreseen_payment"
                type="text"
                value={toCurrency(data.val_foreseen_payment, 2)}
            />
          </div>
        </div>
        {tabIndex === 0 &&
            <div>
              <div className="grid grid-cols-2 gap-x-5">
                <div>
                  <Input
                      id="val_importo_euro"
                      label="Importo Euro (€) "
                      name="val_importo_euro"
                      type="text"
                      value={covered_how === 'COLLAR' ? toCurrency(
                          data.val_foreseen_payment / data.val_exr_hedging_call
                          , 2) : toCurrency(
                          data.val_foreseen_payment / data.val_exr_hedging
                          , 2)}
                  />
                </div>
                <div>
                  <Input
                      id="val_importo_euro_delta"
                      label="Importo Delta (€)"
                      name="val_importo_euro_delta"
                      type="text"
                      value={covered_how === 'COLLAR' ? toCurrency(
                          data.val_foreseen_payment / data.val_exr_hedging_call -
                          data.val_foreseen_payment / data.val_exr_fixed
                          , 2) : toCurrency(
                          data.val_foreseen_payment / data.val_exr_hedging -
                          data.val_foreseen_payment / data.val_exr_fixed
                          , 2)}
                  />
                </div>
              </div>
            </div>
        }
        {tabIndex === 1 &&
            <div>
              <div className="grid grid-cols-2 gap-x-5">
                <div>
                  <Input
                      id="val_importo_euro"
                      label="Importo Euro (€) "
                      name="val_importo_euro"
                      type="text"
                      value={covered_how === 'COLLAR' ? toCurrency(
                          data.val_foreseen_payment / chooseExrCollar(
                              data.val_exr_close,
                              data.val_exr_hedging,
                              data.val_exr_hedging_call)
                          , 2) : toCurrency(
                          data.val_foreseen_payment / chooseExrKiff(
                              data.val_exr_close,
                              data.val_exr_hedging,
                              data.val_exr_hedging_call), 2)}
                  />
                </div>
                <div>
                  <Input
                      id="val_importo_euro_delta"
                      label="Importo Delta (€)"
                      name="val_importo_euro_delta"
                      type="text"
                      value={covered_how === "COLLAR" ? toCurrency(
                          data.val_foreseen_payment / chooseExrCollar(
                              data.val_exr_close,
                              data.val_exr_hedging,
                              data.val_exr_hedging_call) -
                          data.val_foreseen_payment / data.val_exr_fixed
                          , 2) : toCurrency(
                          data.val_foreseen_payment / chooseExrKiff(
                              data.val_exr_close,
                              data.val_exr_hedging,
                              data.val_exr_hedging_call) -
                          data.val_foreseen_payment / data.val_exr_fixed
                          , 2)}
                  />
                </div>
              </div>
            </div>
        }
        {tabIndex === 2 &&
            <div>
              <div className="grid grid-cols-2 gap-x-5">
                <div>
                  <Input
                      id="val_importo_euro"
                      label="Importo Euro (€) "
                      name="val_importo_euro"
                      type="text"
                      value={toCurrency(
                          data.val_foreseen_payment / data.val_exr_hedging
                          , 2)}
                  />
                </div>
                <div>
                  <Input
                      id="val_importo_euro_delta"
                      label="Importo Delta (€)"
                      name="val_importo_euro_delta"
                      type="text"
                      value={toCurrency(
                          data.val_foreseen_payment / data.val_exr_hedging -
                          data.val_foreseen_payment / data.val_exr_fixed
                          , 2)}
                  />
                </div>
              </div>
            </div>
        }
      </div>
  );
}

export default KiffAndCollar;