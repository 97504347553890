import {toCurrency} from "../../../utils";

const options_des_category = {
  "Ordine": "Ordine",
  "Collaudo Interno (F.A.T.)": "Collaudo Interno (F.A.T.)",
  "Collaudo Finale": "Collaudo Finale",
  "Emissione Disegni": "Emissione Disegni",
  "Fattura finale": "Fattura finale",
  "Installazione": "Installazione",
  "Spedizione": "Spedizione",
  "Messa in Marcia": "Messa in Marcia",
  "Anticipo": "Anticipo",
  "Totale": "Totale",
  "Customer Service": "Customer Service",
}

export const getColumns = () => [
  {
    title: "ID",
    field: "id",
    editable: "never",
    hidden: true,
  },
  {
    title: "Codice Ordine",
    field: "cod_order",
    editable: "never",
    render: (v) => (v.cod_order === 9999 ? "Varie" : v.cod_order),
  },
  {
    title: "Tipologia Ordine",
    field: "des_order_type",
    editable: "never",
    lookup: options_des_category
  },
  {
    title: "Codice Copertura",
    field: "cod_hedging",
  },
  {
    title: "Cambio FWD/CAP ACT",
    field: "val_exr_hedging",
    editable: "never",
    render: (v) => toCurrency(v.val_exr_hedging),
  },
  {
    title: "Cambio Floor",
    field: "val_exr_hedging_call",
    editable: "never",
    render: (v) => toCurrency(v.val_exr_hedging_call),
  },
  {
    title: "Cambio contrattuale",
    field: "val_exr_fixed",
    editable: "never",
    render: (v) => toCurrency(v.val_exr_fixed),
  },
  {
    title: "Importo ($)",
    field: "val_foreseen_payment",
    editable: "never",
    render: (v) => toCurrency(v.val_foreseen_payment, 2),
    headerStyle: {whiteSpace: 'nowrap'},
  },
  {
    title: "Valore MTM",
    field: "val_mtm",
    editable: "never",
    render: (v) =>
        toCurrency(v.val_mtm, 2)
  },
  {
    title: " Cop ($)",
    field: "val_hedging",
    editable: "never",
    render: (v) => toCurrency(v.val_hedging, 2),
    headerStyle: {whiteSpace: 'nowrap'},
  },
  {
    title: "Importo Incassato ($)",
    field: "val_paid_actual",
    editable: "never",
    render: (v) => toCurrency(v.val_paid_actual, 2),
    headerStyle: {whiteSpace: 'nowrap'},
  },
  {
    title: "Data Scadenza Copertura",
    field: "dat_expiration_hedging",
    editable: "never",
    type: "date",
    dateSetting: {locale: "it-IT"},
  },
  {
    title: "Data Scadenza",
    field: "dat_expiration",
    editable: "never",
    type: "date",
    dateSetting: {locale: "it-IT"},
  },
  {
    title: "Da coprire",
    field: "product_recommended",
    editable: "never",
    lookup: {'': '', 'forward': 'Forward', 'kiff': 'KIFF', 'collar': 'COLLAR'},
  },
  {
    title: "Coperto",
    field: "covered_how",
    editable: "never",
    lookup: {'': '', 'Forward': 'Forward', 'KIFF': 'KIFF', 'COLLAR': 'COLLAR'},
  },
  {
    title: "Val Order Index",
    field: "val_order_index",
    editable: "never",
    hidden: true,
  },
];

export const OPTIONS = {
  actionsColumnIndex: -1,
  filtering: true,
  showTitle: false,
  toolbar: true,
  paging: true,
  sorting: true,
  pageSizeOptions: [5, 20, 50, 100],
  pageSize: 5,
};

export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    addTooltip: "Aggiungi",
    editTooltip: "Modifica",
    deleteTooltip: "Elimina",
    editRow: {
      deleteText: "Sei sicuro di voler eliminare la riga?",
      saveTooltip: "Salva",
      cancelTooltip: "Annulla",
    },
    emptyDataSourceMessage: "Non sono presenti dati da visualizzare"
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
    firstTooltip: "Prima pagina",
    nextTooltip: "Pagina successiva",
    previousTooltip: "Pagina precedente",
    lastTooltip: "Ultima pagina"
  },
  toolbar: {
    searchTooltip: "Cerca",
    searchPlaceholder: "Cerca"
  }
};



