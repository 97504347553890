import {
  HomeIcon,
  TableCellsIcon,
  ArrowTrendingUpIcon,
  ExclamationTriangleIcon
} from "@heroicons/react/24/outline";
import Home from "@pages/Home";
import Hedging from "@pages/Hedging";
import Forecast from "@pages/Forecast";
import WarningsManagement from "../pages/WarningsManagement";

const routes = [
  {
    name: "Home",
    href: "/home",
    Icon: HomeIcon,
    component: Home,
    hidden: false,
  },
  {
    name: "Hedging",
    href: "/coperture",
    Icon: TableCellsIcon,
    component: Hedging,
    hidden: false,
  },
  {
    name: "Forecast",
    href: "/forecast",
    Icon: ArrowTrendingUpIcon,
    component: Forecast,
    hidden: false,
  },
  {
    name: "Gestione Eccezioni",
    href: "/warnings-management",
    Icon: ExclamationTriangleIcon,
    component: WarningsManagement,
  },
];

export default routes;
