import React from "react";

import Input from "../../ui-components/Input";
import {toCurrency} from "../../utils";

function NotCoveredAndForward({data}) {

  return (
      <div>
        <div className="grid grid-cols-3 gap-x-5">
          <div>
            <Input
                id="val_cambio_copertura"
                label="Tasso copertura"
                name="val_exr_hedging"
                type="text"
                value={data.covered_how !== "" ? data.val_exr_hedging : ""}
            />
          </div>
          <div>
            <Input
                id="val_cambio_actual"
                label="Tasso Actual"
                name="val_exr_close"
                type="text"
                value={data.val_exr_close}
            />
          </div>
          <div>
            <Input
                id="val_cambio_contrattuale"
                label="Tasso Contrattuale"
                name="val_exr_fixed"
                type="text"
                value={data.val_exr_fixed}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-5">
          <div>
            <Input
                id="val_hedging"
                label="Importo Dollari ($)"
                name="val_hedging"
                type="text"
                value={toCurrency(data.val_hedging, 2)}
            />
          </div>
          <div>
            <Input
                id="val_foreseen_payment"
                label="Importo Incasso ($)"
                name="val_foreseen_payment"
                type="text"
                value={toCurrency(data.val_foreseen_payment, 2)}
            />
          </div>
          <div>
            <Input
                id="val_importo_euro"
                label="Importo Euro (€) "
                name="val_importo_euro"
                type="text"
                value={toCurrency(
                    data.val_foreseen_payment / data.val_exr_hedging
                    , 2)}
            />
          </div>
          <div>
            <Input
                id="val_importo_euro_delta"
                label="Importo Delta (€)"
                name="val_importo_euro_delta"
                type="text"
                value={toCurrency(
                    data.val_foreseen_payment / data.val_exr_hedging -
                    data.val_foreseen_payment / data.val_exr_fixed
                    , 2)}
            />
          </div>
        </div>
      </div>
  );
}

export default NotCoveredAndForward;