import {chooseExrCollar, chooseExrKiff} from "../../utils/chooseExr";

export const getRemainingWithActualExchangeRate = (hedging, simulatedRate) =>
    hedging.reduce((acc, row) => {
      const rate =
          simulatedRate && !row.cod_hedging ?
              simulatedRate : (row.covered_how === 'COLLAR' ?
                  chooseExrCollar(row.val_exr_close, row.val_exr_hedging, row.val_exr_hedging_call) :
                  (row.covered_how === 'KIFF' ?
                      chooseExrKiff(row.val_exr_close, row.val_exr_hedging, row.val_exr_hedging_call) :
                      row.val_exr_hedging));
      return acc + (row.val_foreseen_payment - row.val_paid_actual) / rate;
    }, 0);

export const getRemainingWithContractExchange = (hedging) =>
    hedging.reduce(
        (acc, row) =>
            acc +
            (row.val_foreseen_payment - row.val_paid_actual) / row.val_exr_fixed,
        0
    );

export const getRevenue = (hedging, simulatedRate) => {
  if (!hedging) {
    return 0;
  }
  const remainingWithActualExchangeRate = getRemainingWithActualExchangeRate(
      hedging,
      simulatedRate
  );
  const remainingWithContractExchange = getRemainingWithContractExchange(hedging);
  return remainingWithActualExchangeRate - remainingWithContractExchange;
};


export const getRevenuePercentage = (hedging, simulatedRate) => {
  if (!hedging) {
    return 0;
  }
  const remainingWithActualExchangeRate = getRemainingWithActualExchangeRate(
      hedging,
      simulatedRate
  );
  const remainingWithContractExchange = getRemainingWithContractExchange(hedging);
  const percentage =
      (remainingWithActualExchangeRate * 100) / remainingWithContractExchange -
      100;
  return `${percentage > 0 ? "+" : ""}${percentage.toFixed(2)}%`;
};