import React, {useState, useEffect} from "react";
import apiRequest from "../../services/apiRequest";
import ModelTrendLine from "../../components/Charts/ModelTrendLine";


function Forecast() {
  const [dataForecast, setDataForecast] = useState();

  useEffect(() => {
    let clear = false;
    const fetchDataForecast = async () => {
      try {
        const result = await apiRequest.get("forecast");
        if (!clear) {
          setDataForecast(result);
        }
      } catch (error) {
        console.error("Error when fetching forecast data:", error);
        alert("Qualcosa non ha funzionato");
      } finally {
        if (!clear) {
        }
      }
    };

    if (!dataForecast) {
      fetchDataForecast();
    }

    return () => {
      clear = true;
    };
  }, [dataForecast]);

  return (
      <>
        {dataForecast &&
            <div className="flex flex-col gap-y-4 pt-8">
              <div>
                <ModelTrendLine id="forecast-trend-line_short" data={dataForecast} shortTerm={true}/>
              </div>
              <div>
                <ModelTrendLine id="forecast-trend-line_long" data={dataForecast} shortTerm={false}/>
              </div>
            </div>}
      </>
  );
}

export default Forecast;

