import React from "react";

import Profile from "../pages/Profile";
import { Switch } from "react-router-dom";
import PrivateRoute from "../PrivateRoute";
import Container from "../Container";
import menu from "../AppNavigation/menu";
import AppNavigation from "../AppNavigation";

function Dashboard() {
  const routes = menu.reduce(
    (acc, route) => [...acc, ...(route.dropdown ? route.dropdown : [route])],
    []
  );
  return (
    <AppNavigation>
      <Container>
        <Switch>
          {routes.map((route) => {
            return (
              <PrivateRoute
                key={route.href}
                exact={route.exact ?? true}
                component={route.component}
                path={route.href}
                role={route.role}
                scopes={route.scopes}
              />
            );
          })}
          <PrivateRoute exact path="/profile" component={Profile} />
        </Switch>
      </Container>
    </AppNavigation>
  );
}

export default Dashboard;
