export function chooseExrCollar(val_exr_close, val_exr_hedging, val_exr_hedging_call) {
  if (val_exr_close < val_exr_hedging_call) {
    return val_exr_hedging_call
  } else if (val_exr_close > val_exr_hedging) {
    return val_exr_hedging
  } else {
    return val_exr_close
  }
}

export function chooseExrKiff(val_exr_close, val_exr_hedging, val_exr_hedging_call) {
  if (val_exr_close < val_exr_hedging_call) {
    return val_exr_hedging
  } else if (val_exr_close > val_exr_hedging) {
    return val_exr_hedging
  } else {
    return val_exr_close
  }
}