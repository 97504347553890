import React, {useState, useEffect, useContext} from "react";
import apiRequest from "../../services/apiRequest";
import {NotificationsContext} from "../../ui-components/Notifications";
import {PageHeading} from "../../ui-components/Container";
import Alert from "../../ui-components/Alert";
import HedgingSummaryChart from "../../components/Charts/HedgingSummaryChart";
import {toCurrency} from "../../utils";
import {getRevenue} from "./utils";
import CalculatorSimulation from "./components_home/CalculatorSimulation";


function Home() {
  const {push} = useContext(NotificationsContext);
  const [dataSummary, setDataSummary] = useState();
  const [dataExposure, setDataExposure] = useState();
  const [hedging, setHedging] = useState();
  const [actualRevenue, setActualRevenue] = useState(null);
  const [dataWarningsCount, setDataWarningsCount] = useState();
  const [totalHedging, setTotalHedging] = useState();

  const fetchDataSummary = () => {
    apiRequest.get("data_summary")
        .then((result) => {
          setDataSummary(result);
        })
        .catch(() => {
          push({title: "Errore durante il caricamento data summary", type: "error"});
        });
  };

  const fetchDataExposure = () => {
    apiRequest.get("hedging/exposure")
        .then((result) => {
          setDataExposure(result);
        })
        .catch(() => {
          push({title: "Errore durante il caricamento exposures", type: "error"});
        });
  };

  const fetchHedging = () => {
    apiRequest.get("hedging")
        .then((result) => {
          setHedging(result);
        })
        .catch((err) => {
          push({title: "Errore durante il caricamento coperture", type: "error"});
        });
  };

  const fetchDataWarningsCount = () => {
    apiRequest.get(`data_warnings/count`)
        .then((result) => {
          setDataWarningsCount(result);
        })
        .catch((err) => {
          console.error(`Error when fetching data`, err);
        });
  };

  const fetchHedgingConsist = () => {
    apiRequest.get(`data_warnings/total_hedging`)
        .then((result) => {
          setTotalHedging(result);
        })
        .catch((err) => {
          console.error(`Error when fetching data`, err);
        });
  };

  useEffect(() => {
        fetchDataSummary();
        fetchDataExposure();
        fetchHedging();
        fetchDataWarningsCount();
        fetchHedgingConsist();
      }, // eslint-disable-next-line
      []);

  useEffect(() => {
    if (hedging) {
      setActualRevenue(getRevenue(hedging));
    }
  }, [hedging]);

  return (
      <>
        <PageHeading title="Summary"/>
        <div className="flex flex-row gap-x-5 justify-around items-center">
          <div className="flex flex-col gap-y-5">
            {dataSummary &&
                <Alert
                    title={"Ultimo aggiornamento dati: " + dataSummary.dat_ultimo_aggiornamento}
                    type="info"
                />}
            {dataSummary && actualRevenue &&
                <CalculatorSimulation
                    dataSummary={dataSummary}
                    actualRevenue={actualRevenue}
                    hedging={hedging}
                />
            }
          </div>
          <div className="flex flex-col gap-y-2">
            <Alert
                title={"Numero warnings sui dati da gestire: " + (dataWarningsCount ? dataWarningsCount : "")}
                type="warning"
            />
            {totalHedging ? (
                <Alert
                    type={totalHedging.check_status === "KO" ? "warning" : "info"}
                    title={`Piteco ${toCurrency(totalHedging.tot_val_stg, 2)}€ - App ${toCurrency(totalHedging.tot_val_prs
                        , 2)}€ `}
                    text={(totalHedging && totalHedging.array_missing_hedging_codes !== null)
                        ? `Codici Piteco mancanti: ${totalHedging.array_missing_hedging_codes.join(", ")}`
                        : ""}
                />
            ) : null}
            <div className='flex flex-col gap-y-2 flex flex-col items-start'>
              <div>
                {dataExposure &&
                    <HedgingSummaryChart
                        id="chartdiv"
                        data={dataExposure}
                    />
                }
              </div>
            </div>
          </div>
        </div>
      </>
  )
      ;
}


export default Home;
