import jwtDecode from "jwt-decode";
import apiRequest from "../apiRequest";

class AuthenticationService {
  static AUTH_STORAGE_PATH = "logged_user";
  static AUTH_TIME = 2 * 3600; // 2h respect jwt format in seconds

  static getUserData() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));

    if (authObj) {
      return authObj;
    }
    return undefined;
  }

  static getUserScopes() {
    const token = this.getAuthToken();

    if (token) {
      const {scopes} = jwtDecode(token);

      return scopes;
    }
    return [];
  }

  static getNowEpoch() {
    return Date.now() / 1000; // respect jwt format in seconds
  }

  static getAuthToken() {
    const authObj = JSON.parse(localStorage.getItem(this.AUTH_STORAGE_PATH));

    if (authObj && authObj.access_token) {
      if (
        (authObj.expires && authObj.expires_at > this.getNowEpoch()) ||
        !authObj.expires
      )
        return authObj.access_token;

      this.deleteAuthToken();
      return undefined;
    }
    return undefined;
  }

  static setAuthToken(token, userData, expires = true) {
    const now = this.getNowEpoch();

    const {exp} = jwtDecode(token);
    const expires_at = exp || (expires ? now + this.AUTH_TIME : -1);

    const authObj = {
      ...userData,
      access_token: token,
      expires,
      expires_at,
    };

    localStorage.setItem(this.AUTH_STORAGE_PATH, JSON.stringify(authObj));
  }

  static deleteAuthToken() {
    localStorage.removeItem(this.AUTH_STORAGE_PATH);
  }

  static async login(username, password) {
    const data = new FormData();
    data.append("username", username);
    data.append("password", password);

    const {access_token, user_data} = await apiRequest.post(
      "/auth/login",
      data,
      {
        publicRequest: true,
        headers: {"Content-Type": "multipart/form-data"},
      }
    );

    const token = access_token;
    if (token) {
      this.setAuthToken(token, user_data);
    }

    window.location = "/home";
  }

  static async getOauth2Url() {
    return apiRequest.get("/oauth2/login", {publicRequest: true})
      .then(data => {
        let url = new URL("authorize", data["oauth_authority"]);
        const searchParams = new URLSearchParams({
          client_id: data["client_id"],
          scope: data["scope"],
          response_type: data["response_type"],
          redirect_uri: data["redirect_uri"],
          prompt: "select_account"
        });
        url.search = searchParams.toString();
        return url;
      })
  }

  static logout() {
    this.deleteAuthToken();
  }

  static isUserAuthenticated() {
    const access_token = this.getAuthToken();
    return !!access_token;
  }

  static authHeader() {
    if (this.isUserAuthenticated())
      return {
        Authorization: `Bearer ${this.getAuthToken()}`,
      };

    return false;
  }
}

export default AuthenticationService;
