import React, {useLayoutEffect} from "react";
import _ from "lodash";

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {addLicense} from "./commons";


function HedgingSummaryChart({id, data}) {

  useLayoutEffect(() => {

    addLicense();
    let root = am5.Root.new(id);
    root.setThemes([
      am5themes_Animated.new(root)
    ]);

// Create chart
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      layout: root.verticalLayout
    }));

    let dataChart = data.map((i) => ({
      ...i,
      val_residuo: i["val_exposure"] - i["val_hedging"],
    }));

    dataChart = _.sortBy(
        dataChart.map((i) => ({
          ...i,
          percHDG:
              i["val_exposure"] > 0
                  ? Math.round((i["val_hedging"] / i["val_exposure"]) * 100)
                  : 0,
          percRSD:
              i["val_exposure"] > 0
                  ? Math.round((i["val_residuo"] / i["val_exposure"]) * 100)
                  : 0,
        })),
        "val_exposure"
    );


// Create axes
    let yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "des_order_type",
      renderer: am5xy.AxisRendererY.new(root, {}),
    }));

    let yRenderer = yAxis.get("renderer");
    yRenderer.labels.template.setAll({
      fontSize: "0.8em",
      fontWeight: "bold"
    });

    //Set the order of columns
    yAxis.data.setAll([{des_order_type: 'Varie'}, {des_order_type: 'CS'}
      , {des_order_type: 'Pre'}, {des_order_type: 'Consegna'}
      , {des_order_type: 'Post'}, {des_order_type: 'Totale'}
    ]);

    let xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {}),
      min: 0,
      strictMinMax: true,
    }));

    // Cursor zoom for xaxis
    chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "zoomX",
      xAxis: xAxis
    }));

    let xRenderer = xAxis.get("renderer");
    xRenderer.labels.template.setAll({
      fontSize: "0.5em",
    });


// Add legend
    let legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));


// Add series
    function makeSeries(name, fieldName, color) {
      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        valueXField: fieldName,
        categoryYField: "des_order_type",
        stroke: am5.color("#2C2A26"),
      }));

      series.columns.template.setAll({
        tooltipText: `Esposizione: [bold]{val_exposure}[/] \n` +
            "{name}: [bold]{valueX}[/] " + (fieldName === "val_hedging" ? "({percHDG}%)" : "({percRSD}%)"),
        tooltipY: am5.percent(90),
        fillOpacity: 0.9,
        fill: color,
        strokeWidth: 1.2,
      });


      series.data.setAll(dataChart);

      // Make stuff animate on load
      series.appear(2000);

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            centerY: am5.p50,
            centerX: am5.p50,
          })
        });
      });

      legend.data.push(series);
    }

    makeSeries("Coperture", "val_hedging", am5.color("#9CA79D"));
    makeSeries("Residuo", "val_residuo", am5.color("rgb(0, 54, 70)"));


    chart.appear(1000, 100);
    return () => {
      root.dispose()
    }
  }, [data, id])


  return <div id={id} style={{minWidth: "650px", minHeight: "350px"}}/>
}

export default React.memo(HedgingSummaryChart, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
