// Services
import React, {useState, useEffect} from "react";
import apiRequest from "../../services/apiRequest";

// UI
import DataWarningsTable from "../../components/Tables/DataWarningsTable";
import {PageHeading} from "../../ui-components/Container";


export default function WarningsManagement() {
  const [data, setData] = useState();

  const fetchUsers = () => {
    apiRequest.get("data_warnings")
        .then((result) => {
          setData(result);
        })
        .catch((err) => {
          console.error("Error when fetching hedging data:", err);
        });
  };

  useEffect(() => {
    if (!data) {
      fetchUsers();
    }
  }, [data]);


  return (
      <>
        <PageHeading title="Gestione eccezioni"/>
        <DataWarningsTable
            data={data}
            setData={setData}
        />
      </>
  );
}
