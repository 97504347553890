import React from "react";
import {TypesLogoProps} from "./logo.types";

const Logo = ({height = "h-24", width = "w-auto", imagePath = process.env.PUBLIC_URL + "/logo.svg"}: TypesLogoProps) =>
  <div className="flex shrink-0 mt-4 items-center m-0">
    <img
      className={`${height} ${width} object-contain mx-auto`}
      src={imagePath}
      alt="ammagamma logo"
    />
  </div>;

export default Logo;
