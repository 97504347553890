import _ from "lodash";
import React, {useState} from "react";
import moment from "moment";

// UI
import MaterialTable from "@material-table/core";

// Table Params
import {getColumns, LOCALIZATION, OPTIONS} from "./Params/Hedging";
import {tableIcons} from "../../ui-components/TableIcons";

// Services
import apiRequest from "../../services/apiRequest";
import TableStyle from "../../ui-components/TableStyle";
import {downloadExcel} from "../../utils/downloadExcel";

function HedgingTable({data, setData, push, openHedgingDetails}) {
  const ref = React.useRef();
  const [columns] = useState(getColumns());

  const _bulkUpdate = (changes) =>
      new Promise((resolve, reject) => {
        setImmediate(() => {
          // prepare data and convert date type to string
          const prepareData = Object.keys(changes).map((k) =>
              _.pick(changes[k]["newData"], _.map(columns, "field"))
          );
          _.map(prepareData, (obj) => {
            obj.date = moment(obj.date).format("YYYY-MM-DD");
            return obj;
          });
          apiRequest
              .post("/hedging/bulk", prepareData)
              .then((result) => {
                const editedResult = (result || []).reduce(
                    (obj, row) => _.set(obj, [row.id], row),
                    {}
                );
                setData({...data, ...editedResult});
                push({title: "Righe aggiornate con successo", type: "success"});
                resolve();
              })
              .catch(() => {
                push({
                  title:
                      "Errore imprevisto durante l'aggiornamento multiplo delle righe",
                  type: "error",
                });
                reject();
              });
        });
      });

  return (
      <>
        <TableStyle>
          <MaterialTable
              tableRef={ref}
              columns={columns}
              data={data && Object.values(data)}
              icons={tableIcons}
              isLoading={data === undefined}
              options={OPTIONS}
              localization={LOCALIZATION}
              editable={{
                onBulkUpdate: _bulkUpdate,
              }}
              actions={[
                {
                  icon: tableIcons.Download,
                  tooltip: "Esporta in Excel",
                  onClick: () => downloadExcel(ref.current.state.data, columns),
                  isFreeAction: true,
                },]}
              onRowClick={(event, rowData) => openHedgingDetails(rowData)}
          />
        </TableStyle>
      </>
  );
}

export default React.memo(HedgingTable, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data);
});