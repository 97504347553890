import React from "react";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";

const THEME = createTheme({
  palette: {},
  overrides: {
    MuiPaper: {
      elevation2: {
        boxShadow: "none",
        backgroundColor: "#f5f5f5",
      },
    },
    MuiTable: {
      root: {
        padding: "0",
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: "#00475C",
        borderRadius: 0,
        textTransform: "uppercase",
        fontWeight: 500,
        color: "white",
      },
    },
    MuiInputBase: {
      root: {
        border: "1px solid #00475C",
        borderRadius: "1rem",
        marginRight: "0px",
        padding: "0 0px",
        overflow: "hidden",
        "&&&:before": {borderBottom: "0 !important", content: "none"},
        "&&&:after": {borderBottom: "0 !important", content: "none"},
        "&:focus": {border: "0", outlineOffset: "0", boxShadow: "none"},
      },
      inputTypeSearch: {
        "&:focus": {border: "0", outlineOffset: "0", boxShadow: "none"},
      },
      input: {
        "&:focus": {border: "0", outlineOffset: "0", boxShadow: "none"},
      },
    },
    MuiInputAdornment: {
      root: {
        marginLeft: "0px",
      },
    },
    MuiTableRow: {
      root: {
        border: "none",
        fontSize: 12,
      },
    },
    MuiFormControl: {
      root: {
        "&&&:before": {borderBottom: "none"},
        "&&:after": {borderBottom: "none"},
      },
    },
    MuiFormControlLabel: {
      root: {
        "&$root": {
          marginLeft: 0
        }
      }
    },
    MuiCheckbox: {
      root: {
        marginLeft: 0
      },
      colorSecondary: {
        color: "#00475C",
        "&$checked": {
          color: "#00475C"
        }
      },
    },
    MuiTableCell: {
      root: {
        border: "none",
        backgroundColor: "#f5f5f5",
        padding: "1em 0.5em !important",
        textAlign: "center !important",
      },
      head: {
        backgroundColor: "transparent !important",
        border: "none",
        borderRadius: 0,
        padding: "1.5em 1em 0.5em !important",
        color: "#f5f5f5",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: 11
      },
    },
    MuiTableSortLabel: {
      root: {
        "&:hover": {color: "#f5f5f5"},
        display: "flex",
        flexDirection: "column",
        marginRight: "0px"
      },
      active: {
        color: "#f5f5f5 !important"
      },
      icon: {
        color: "#f5f5f5 !important",
      },
    },
  },
});

const TableStyle = ({children}) => {
  return (
      <div className="rounded-3xl overflow-hidden">
        <ThemeProvider theme={THEME}>{children}</ThemeProvider>
      </div>
  );
};

export default TableStyle;
