import React, {useContext, useEffect, useState} from "react";
import apiRequest from "../../../services/apiRequest";
import {NotificationsContext} from "../../Notifications";
import {PageHeading, PageSection} from "../../Container";
import DatePicker from "../../DatePicker";
import RadioGroup from "../../RadioGroup";
import moment from "moment";
import LogBackendDetailTable from "../../../components/Tables/LogBackendDetailTable";
import LogBackendErrorTable from "../../../components/Tables/LogBackendErrorTable";
import Placeholder from "../../Placeholder";
import StackedColumnChart from "../../../components/Charts/StackedColumnChart";
import { ExclamationCircleIcon, FaceSmileIcon } from "@heroicons/react/24/outline";



const AuditBackend = () => {

  const chartHeight = "450px"

  const {push} = useContext(NotificationsContext);

  const [loading, setLoading] = useState(false);
  const [backendDetailLog, setBackendDetailLog] = useState();
  const [backendErrorLog, setBackendErrorLog] = useState();
  const [backendGroupedLog, setBackendGroupedLog] = useState();
  const [fetchResult, setFetchResult] = useState();
  const [distinctDimensions, setDistinctDimensions] = useState({});
  const [datePicker, setDatePicker] = useState(new Date());
  const [aggregation, setAggregation] = useState("giorno");
  const [filter, setFilter] = useState("route");

  const getColumnsStackedChartBe = (data, filterColumn, sumValue) => {

    if (!data)
      return

    let filtered_results;
    // rimuoviamo da result i campi che non ci servono
    switch (filterColumn) {
      case 'status_code':
        filtered_results = data.map(el => (
          {
            "date": el.date,
            "status_code": el.status_code,
            "API_calls": el.API_calls
          }
        ))
        break;
      case 'route':
        filtered_results = data.map(el => {
          const controller = el.route.substring(1)
          return (
            {
              "date": el.date,
              "route": controller.indexOf('/') > 0 ? controller.substring(0, controller.indexOf('/')) : controller,
              "API_calls": el.API_calls
            }
          )
        })
        break;
      default:
        break;
    }
    let uniqueRoutes = []

    let res = filtered_results.reduce((acc, el) => {
        if (!uniqueRoutes.includes(el[filterColumn]))
          uniqueRoutes.push(el[filterColumn])

        if (Object.keys(acc).includes(el['date'])) {
          let dateObj = acc[el['date']];
          if (Object.keys(dateObj).includes(el[filterColumn].toString())) {
            dateObj[el[filterColumn]] += el[sumValue]
          } else {
            dateObj[el[filterColumn]] = el[sumValue]
          }
          acc[el['date']] = dateObj;
        } else {
          acc[el['date']] = {[el[filterColumn]]: el[sumValue]}
        }
        return acc
      },
      {});

    res = Object.keys(res).map((key) => {
      const el = res[key];
      return {
        date: new Date(key).getTime(),
        ...el
      }
    })
    return [res, uniqueRoutes]
  }

  useEffect(() => {

    let fetchBackendDetail = apiRequest.get(`/audit/requests/${moment(datePicker).format('YYYY-MM-DD')}`);
    let fetchBackendError = apiRequest.get(`/audit/error_backend/${moment(datePicker).format('YYYY-MM-DD')}`);

    // Promise all serve per fare più chiamate API contemporaneamente
    Promise.all([fetchBackendDetail, fetchBackendError])
      .then((logs) => {
        setBackendDetailLog(logs[0]);
        setBackendErrorLog(logs[1]);
      })
      .catch(() => {
        push({
          title: "server_error",
          type: "error",
        });
      });
  }, [datePicker]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {

    let groupParams = {
      grouping: aggregation
    }

    let groupParamsURL = new URLSearchParams(groupParams)


    
    apiRequest.get(`/audit/requests_grouped?${groupParamsURL}`)
      .then((result) => {
        setFetchResult(result);
      })
      .catch(() => {
        push({
          title: "Caricamento fallito in dati errori a backend o a dettaglio backend",
          type: "error",
        });
      })
  }, [datePicker, aggregation]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!fetchResult)
      return

    const reducedStackedChartData = getColumnsStackedChartBe(fetchResult, filter, "API_calls");
    setBackendGroupedLog(reducedStackedChartData[0]);
    setDistinctDimensions(reducedStackedChartData[1]);
    setLoading(false);
  }, [fetchResult, filter])

  const missingData = () => {
    return <p className=" w-full text-center my-10">
      <ExclamationCircleIcon className="h-6 w-6 text-gray-400 inline-flex mr-2"/>
      Dati non disponibili
    </p>
  }

  return (<>
    <PageHeading
      title="Backend"
    />
    <div className="flex flex-col md:flex-row justify-between items-center mx-14 mb-10">
      <RadioGroup
        id="group1"
        title="Aggregazione"
        options={[
          {value: "giorno", name: "giorno"},
          {value: "mese", name: "mese"},
        ]}
        currentValue={aggregation}
        onChange={(value) => {
          setAggregation(value);
        }}
      />
      <div className="mt-10 md:mt-0">
        <RadioGroup
          id="group2"
          title="Filtro"
          options={[
            {value: "status_code", name: "status"},
            {value: "route", name: "controller"},
          ]}
          currentValue={filter}
          onChange={(value) => {
            setFilter(value);
          }}
        />
      </div>
    </div>
    {loading
      ? <Placeholder style={{minHeight: chartHeight}}/>
      :
      <PageSection title="Numero Chiamate API">
        {backendGroupedLog?.length ?
          <StackedColumnChart
            id="backend-barchart" chartHeight={chartHeight}
            dimensions={distinctDimensions}
            data={backendGroupedLog}
            aggregation={aggregation}
          /> :
          missingData()
        }
      </PageSection>
    }
    <div className="w-36 mt-10">
        <DatePicker
          value={datePicker}
          showErrorDialog={false}
          onChange={setDatePicker}
          label="Data Selezionata"
          placeholder="Seleziona data"
          errorMessage="Seleziona data"
          minDate={new Date((new Date().getFullYear() - 1).toString())}
        />
      </div>
    <div className="my-5">
      <PageSection title="Dettaglio Chiamate Backend">
        {backendDetailLog?.length ?
          <LogBackendDetailTable data={backendDetailLog}/> :
          missingData()
        }
      </PageSection>
    </div>
    <PageSection title="Dettaglio Errori Backend">
      {backendErrorLog?.length ?
        <LogBackendErrorTable data={backendErrorLog}/> :
        <p className="my-10 w-full text-center">
          <FaceSmileIcon className="h-6 w-6 text-green-700 inline-flex mr-2"/>
          Non sono presenti errori nel periodo selezionato
        </p>
      }
    </PageSection>
  </>);
}

export default AuditBackend;