import React from "react";

// Service

// UI
import _ from "lodash";
import MaterialTable from "@material-table/core";

// Table Params
import {COLUMNS, OPTIONS, LOCALIZATION} from "./Params/DataWarnings";
import Button from "../../ui-components/Button";
import {tableIcons} from "../../ui-components/TableIcons";
import TableStyle from "../../ui-components/TableStyle";
import apiRequest from "../../services/apiRequest";
import {downloadExcel} from "../../utils/downloadExcel";

function DataWarningsTable({data, setData}) {
  const ref = React.useRef(null);

  const _data =
      data &&
      Object.values(data).map((i) => ({
        ...i,
      }));

  return (
      <>
        <TableStyle>
          <MaterialTable
              tableRef={ref}
              columns={[
                ...COLUMNS,
                {
                  title: "Nascondi",
                  render: (row) => (
                      <Button
                          variant="contained"
                          onClick={async () => {
                            try {
                              const {id, tableData, ...rest} = row;
                              await apiRequest.post(
                                  `data_warnings/remove_exception`,
                                  rest
                              );
                              const newData = {...data};
                              delete newData[tableData.id];
                              setData(newData);
                            } catch (error) {
                              alert("Qualcosa non ha funzionato");
                            }
                          }}
                      >
                        NASCONDI
                      </Button>
                  ),
                },
              ]}
              data={_.values(_data)}
              icons={tableIcons}
              isLoading={_data === undefined}
              options={OPTIONS}
              localization={LOCALIZATION}
              actions={[
                {
                  icon: tableIcons.Download,
                  tooltip: "Esporta in Excel",
                  onClick: () => downloadExcel(ref.current.state.data, COLUMNS),
                  isFreeAction: true,
                },
              ]}
          />
        </TableStyle>
      </>
  );
}


export default React.memo(DataWarningsTable, (props, nextProps) => {
  // Render component only when data changes
  return _.isEqual(props.data, nextProps.data);
});
