import React, {useState, useEffect, useContext} from "react";
import apiRequest from "../../services/apiRequest";
import {NotificationsContext} from "../../ui-components/Notifications";
import {PageHeading} from "../../ui-components/Container";
import HedgingTable from "../../components/Tables/HedgingTable";
import HedgingDetailsDialog from "../../components/Dialogs/HedgingDetailsDialog";
import DatePicker from "../../ui-components/DatePicker";
import Button from "../../ui-components/Button";
import {toCurrency} from "../../utils";
import _ from "lodash";
import {chooseExrCollar, chooseExrKiff} from "../../utils/chooseExr";


function Hedging() {
  const {push} = useContext(NotificationsContext);
  const [dataTable, setDataTable] = useState([]);
  const [dataSummary, setDataSummary] = useState();
  const [rowData, setRowData] = useState({});
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [button, setButton] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();


  const toggleOpen = () => {
    setOpen(!open);
    setTabIndex(0)
  };

  const openHedgingDetails = (_rowData) => {
    setRowData(_rowData);
    toggleOpen();
  };

  function getFormattedDate(date, delimiter) {
    if (date === undefined) {
      return date
    } else {
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      return year + delimiter + month + delimiter + day
    }
  }

  const fetchMinMaxDate = () => {
    apiRequest.get("hedging/min_max_date")
        .then((result) => {
          setMinDate(new Date(result[0].min_date));
          setMaxDate(new Date(result[0].max_date));
        })
        .catch(() => {
          push({title: "Errore durante il caricamento dei dati della tabella", type: "error"});
        });
  };

  const fetchHedgingData = () => {
    let start_date = getFormattedDate(startDate, '-')
    let end_date = getFormattedDate(endDate, '-')
    apiRequest.get(`hedging/${start_date}/${end_date}`)
        .then((result) => {
          // setDataTable(result);
          setDataTable((result || []).reduce((obj, row) => _.set(obj, [row.id], row), {}));
        })
        .catch(() => {
          push({title: "Errore durante il caricamento dei dati della tabella", type: "error"});
        });
  };

  const fetchHedgingDataSummary = () => {
    let start_date = getFormattedDate(startDate, '-')
    let end_date = getFormattedDate(endDate, '-')
    apiRequest.get(`hedging/data-summary/${start_date}/${end_date}`)
        .then((result) => {
          setDataSummary(result[0]);
        })
        .catch(() => {
          push({title: "Errore durante il caricamento del data summary", type: "error"});
        });
  };

  Object.values(dataTable).forEach(row => {
    if (row.cod_job !== 'Varie') {
      row.val_mtm = (row.val_hedging /
          (row.covered_how === 'KIFF' ?
              chooseExrKiff(row.val_exr_close, row.val_exr_hedging, row.val_exr_hedging_call)
              : (row.covered_how === 'COLLAR' ?
                  chooseExrCollar(row.val_exr_close, row.val_exr_hedging, row.val_exr_hedging_call)
                  : row.val_exr_hedging))) - (row.val_hedging / row.val_exr_close)
    }
  })

  useEffect(() => {
        fetchHedgingData();
        fetchHedgingDataSummary();
        fetchMinMaxDate();
      }, // eslint-disable-next-line
      [button]);

  return (
      <>
        <PageHeading title="Coperture"/>
        <div>
          <div>
            {minDate && maxDate && (
                <div className="grid grid-cols-4 gap-x-5 mb-5">
                  <div>
                    <DatePicker
                        value={startDate}
                        onChange={setStartDate}
                        label="Data inizio"
                        placeholder={minDate.toLocaleDateString()}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                  </div>
                  <div>
                    <DatePicker
                        value={endDate}
                        onChange={setEndDate}
                        label="Data fine"
                        placeholder={maxDate.toLocaleDateString()}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                  </div>
                  <div className="self-end">
                    <Button
                        color="primary"
                        onClick={() => {
                          setButton(!button)
                        }}
                    >
                      APPLICA FILTRO
                    </Button>
                  </div>
                </div>)
            }
          </div>
          {dataSummary && (
              <div className="grid grid-cols-6 items-center p-2 mb-5">
                <div className="flex flex-col text-center">
                  <h1 className="text-xs leading-none tracking-tighter text-gray-900">Tot. Esposizioni ($)</h1>
                  <h1 className="text-xl font-bold text-am-600">{toCurrency(dataSummary['val_exposure_tot'], 2)}</h1>
                </div>
                <div
                    className="flex flex-col text-center">
                  <h1 className="text-xs leading-none tracking-tighter text-gray-900">Tot. Coperture ($)</h1>
                  <h1 className="text-xl font-bold text-am-600">{toCurrency(dataSummary['val_hedging_tot'], 2)}</h1>
                </div>
                <div className="flex flex-col text-center">
                  <h1 className="text-xs leading-none tracking-tighter text-gray-900">Exr medio contratti di
                    copertura</h1>
                  <h1 className="text-xl font-bold text-am-600">{toCurrency(dataSummary.val_exr_hedging_covered_avg)}</h1>
                </div>
                <div className="flex flex-col text-center">
                  <h1 className="text-xs leading-none tracking-tighter text-gray-900">Exr medio tot. ordini coperti</h1>
                  <h1 className="text-xl font-bold text-am-600">{toCurrency(dataSummary.val_exr_fixed_covered_avg)}</h1>
                </div>
                <div className="flex flex-col text-center">
                  <h1 className="text-xs leading-none tracking-tighter text-gray-900">Exr medio tot. ordini non
                    coperti</h1>
                  <h1 className="text-xl font-bold text-am-600">{toCurrency(dataSummary.val_exr_fixed_not_covered_avg)}</h1>
                </div>
                <div className="flex flex-col text-center">
                  <h1 className="text-xs leading-none tracking-tighter text-gray-900">Exr medio tot. ordini</h1>
                  <h1 className="text-xl font-bold text-am-600">{toCurrency(dataSummary.val_exr_fixed_avg)}</h1>
                </div>
              </div>
          )}
        </div>
        <HedgingTable
            data={dataTable}
            setData={setDataTable}
            push={push}
            openHedgingDetails={openHedgingDetails}
        />
        <HedgingDetailsDialog
            open={open}
            toggleOpen={toggleOpen}
            data={rowData}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
        />
      </>
  )
      ;
}

export default Hedging;
