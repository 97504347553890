import React, { Fragment, useCallback, useState } from "react";
import Input from "../Input";
import { useIntl } from "react-intl";

const UpdatePassword = ({
  setPassword,
  setConfirmPassword,
  password,
  confirmPassword,
  showPassword,
}) => {
  const intl = useIntl();
  const [confirmError, setConfirmError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handlePassword = useCallback(
    (value) => {
      if (value.length < 8) {
        setPasswordError(intl.formatMessage({ id: "password_length" }));
        return false;
      } else if (value.includes(" ")) {
        setPasswordError(intl.formatMessage({ id: "password_without_spaces" }));
        return false;
      }

      setPasswordError();
      return true;
    },
    [intl]
  );

  const handleConfirmPassword = useCallback(
    (value) => {
      if (value !== password) {
        setConfirmError(intl.formatMessage({ id: "passwords_not_matching" }));
        return false;
      }
      setConfirmError();
      return true;
    },
    [password, intl]
  );

  return (
    <Fragment>
      <div>
        <Input
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
          }}
          label="Password"
          type={showPassword ? "text" : "password"}
          validation={handlePassword}
          errorMessage={passwordError}
          required
        />
      </div>
      <div>
        <Input
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          validation={handleConfirmPassword}
          label={intl.formatMessage({ id: "confirm_password" })}
          type={showPassword ? "text" : "password"}
          errorMessage={confirmError}
          required
        />
      </div>
    </Fragment>
  );
};

export default UpdatePassword;
