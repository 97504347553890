import {toCurrency} from "../../../utils";

export const COLUMNS = [
  {
    title: "ID",
    field: "id",
    editable: "never",
    hidden: true
  },
  {
    title: "Codice Ordine",
    field: "cod_order",
    editable: "never",
  },
  {
    title: "Importo ($)",
    field: "val_foreseen_payment",
    editable: "never",
    render: (v) =>
        v.val_foreseen_payment
            ? toCurrency(v.val_foreseen_payment, 2)
            : v.val_payment
                ? toCurrency(v.val_payment, 2)
                : "",
  },
  {
    title: "Codice Tipologia Ordine",
    field: "cod_order_type",
    editable: "never",
  },
  {
    title: "Tipologia Ordine",
    field: "des_order_type",
    editable: "never",
  },
  {
    title: "Warning",
    field: "des_warning_type",
    editable: "never",
  },
];


export const OPTIONS = {
  actionsColumnIndex: -1,
  filtering: true,
  showTitle: false,
  toolbar: true,
  paging: true,
  sorting: true,
  pageSizeOptions: [5, 20, 50, 100],
  pageSize: 5,
};


export const LOCALIZATION = {
  header: {
    actions: ""
  },
  body: {
    addTooltip: "Aggiungi",
    editTooltip: "Modifica",
    deleteTooltip: "Elimina",
    editRow: {
      deleteText: "Sei sicuro di voler eliminare la riga?",
      saveTooltip: "Salva",
      cancelTooltip: "Annulla",
    },
    emptyDataSourceMessage: "Non sono presenti dati da visualizzare"
  },
  pagination: {
    labelDisplayedRows: "{from}-{to} di {count} righe",
    labelRowsSelect: "Righe",
    firstTooltip: "Prima pagina",
    nextTooltip: "Pagina successiva",
    previousTooltip: "Pagina precedente",
    lastTooltip: "Ultima pagina"
  },
  toolbar: {
    searchTooltip: "Cerca",
    searchPlaceholder: "Cerca"
  }
};
