import en_faq from "./faq/en_faq";

const en = {
  entity_user: "Management of users in the system",
  entity_role: "Management of authorization roles in the system",
  entity_audit: "Management of audit in the system",
  name_and_surname: "Name and surname",
  Forecast: "Forecast",
  language_it: "Italiano",
  language_en: "English",
  language: "Language",
  Hedging: "Hedging",
  Home: "Home",
  disabled_user: "Disabled user",
  enter_name_and_surname: "Enter your name and surname",
  enter_name_or_email: "Enter email and name",
  need_to_select_a_role: "You must select a role",
  delete_user: "Delete the user?",
  disabled: "Disabled",
  user_updated_successfully: "User updated successfully",
  user_deleted_successfully: "User successfully deleted",
  user_created_successfully: "User successfully created",
  description: "Description",
  role_name: "Role name",
  add_role_name: "Enter the name of the role",
  add_role: "Add role",
  add_user: "Add user",
  deleted_role: "Role deleted",
  updated: "Updated",
  delete_element: "Are you sure you want to delete the element?",
  role_already_used: "Role already in use!",
  role_created_successfully: "Role created successfully",
  add: "Add",
  server_error: "Server Error",
  confirm_password: "Confirm password",
  passwords_not_matching: "The two passwords do not match",
  password_without_spaces: "The password must not contain spaces",
  password_length: "The password must be at least 8 characters long",
  signup: "Register",
  error_while_saving: "Error while saving",
  authentication_failed: "Authentication failed",
  send: "Send",
  password_policy: "Enter a password of at least 8 characters, without spaces",
  enter_actual_password: "Enter current password",
  email: "Email",
  name: "Name",
  role: "Role",
  user_type: "User type",
  my_profile: "My profile",
  access_denied: "Access denied",
  password_updated_successfully: "Password changed successfully",
  submit_password: "Send password",
  enter_your_email_address: "Enter the email address associated with your user",
  restore_password: "Recover password",
  forgot_password: "Forgot your password?",
  edit_password: "Change password",
  show_password: "Show password",
  enter_email: "Enter email",
  log_into_your_account: "Log into your account",
  wrong_email_or_password: "Wrong email or password",
  unregistered_email: "Email not registered",
  roles: "Roles",
  roles_displayed_rows: "{from}-{to} of {count} roles",
  users: "Users",
  users_displayed_rows: "{from}-{to} of {count} users",
  components: "Components",
  maps: "Maps",
  tables: "Tables",
  graphs: "Graphs",
  users_settings: "User management",
  welcome: "Welcome",
  test: "test_it",
  audit: "Audit",
  frontend: "Frontend",
  backend: "Backend",
  file_picker_placeholder: "Select one or more files",
  success_ticket_sent: "The ticket was added successfully, we will take the request in charge as soon as possibile.",
  hubspot_portal: "Hubspot Customer Portal",
  size_limit_exceeded: "Size limit exceeded",
  customer_portal_loading_failed: "Customer portal link loading error",
  week_datepicker: "Week ",
  week_datepicker_not_selected: "Weekly Datepicker",
  daily_datepicker_not_selected: "Daily Datepicker",
  thanks: "Thanks!",
  select_an_option: "Please select an option from the dropdown menu.",
  ticket_parameters_not_found: "Ticket parameters (access token and company ID) not found",
  error_new_ticket_creation: "An error occurred while creating a new ticket",
  error_ticket_hubspot_management: "New ticket is created, but went wrong during the operations made by our portal",
  error_ticket_file_attachment: "New ticket is created, but it's not possibile to attach the file specified",
  ...en_faq,
};

export default en;
