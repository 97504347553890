import {toCurrency} from "../../../utils";
import {getRevenue, getRevenuePercentage} from "../utils";
import React, {useState} from "react";
import _ from "lodash";

function CalculatorSimulation({dataSummary, actualRevenue, hedging}) {
  const [simulatedExchangeRate, setSimulatedExchangeRate] = useState(1.2);

  return <>
    <div className="flex flex-row rounded-3xl bg-gray-100 border-2 border-am-800 gap-x-16 p-10">
      <div className="flex flex-col items-center">
        <h1 className="text-xl font-bold text-gray-900 mb-5">Actual</h1>
        <div className="flex flex-col text-center mb-7">
          <h1 className="text-xs text-gray-900">Cambio attuale</h1>
          <h1 className="text-xl font-bold text-am-200">{dataSummary && toCurrency(dataSummary.val_cambio, 2)}</h1>
        </div>
        <div className="flex flex-col text-center mb-5">
          <h1 className="text-xs text-gray-900">Utile/Perdita (€)</h1>
          <h1 className="text-xl font-bold text-am-200">{actualRevenue && toCurrency(actualRevenue, 2)}</h1>
        </div>
        <div className="flex flex-col text-center">
          <h1 className="text-xs text-gray-900">Utile/Perdita (%)</h1>
          <h1 className="text-xl font-bold text-am-200">{getRevenuePercentage(hedging)}</h1>
        </div>
      </div>
      <div className="flex flex-col text-center items-center">
        <h1 className="text-xl font-bold text-gray-900 mb-5">Simulation</h1>
        <div className="text-xs text-gray-900 mb-4">
          <p className="text-xs text-gray-900">Cambio simulato</p>
          <input
              className="border-2 border-am-200 block w-24 sm:text-sm font-bold rounded-md"
              size="small"
              type="number"
              step="0.1"
              min="0"
              id="cambio-attuale-simulato"
              onChange={(e) =>
                  setSimulatedExchangeRate(parseFloat(e.target.value))
              }
              value={simulatedExchangeRate}
          />
        </div>
        <div className="flex flex-col text-center mb-5">
          <h1 className="text-xs text-gray-900">Utile/Perdita (€)</h1>
          <h1 className="text-xl font-bold text-am-200">{toCurrency(
              getRevenue(hedging, simulatedExchangeRate),
              2
          )}</h1>
        </div>
        <div className="flex flex-col text-center">
          <h1 className="text-xs text-gray-900">Utile/Perdita (%)</h1>
          <h1 className="text-xl font-bold text-am-200">{getRevenuePercentage(hedging, simulatedExchangeRate)}</h1>
        </div>
      </div>
    </div>
  </>
}

export default React.memo(CalculatorSimulation, (props, nextProps) => {
  return _.isEqual(props.data, nextProps.data)
})
